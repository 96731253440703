import { FormValidation } from '@rjsf/core'
import { isString } from '@utils/stringUtils'
import { TFunction } from 'i18next'
import { JSONSchema7, JSONSchema7Definition } from 'json-schema'

import { validateFormat, validateString } from '@components/RJSF/customFormats'
import { InsuranceFormData } from '@components/RJSF/widgets/InsuranceWidget'
import { jsonSchemaCustom } from '@root/anz/types/anzTypes'
import { QuestionAnswer } from '@root/api/models/Form'
import { Config } from '@root/types/config'

export type ValidateFormatReturnType = ReturnType<typeof validateFormat>
export type CustomValidateFunctionType = {
  formData: QuestionAnswer
  errors: FormValidation
  t: TFunction
  customFormats: ValidateFormatReturnType
  country?: string
  properties?: {
    [key: string]: JSONSchema7Definition
  }
  config: Config
}

type CheckMutuallyExclusiveProperties = (
  errors: FormValidation,
  formData: QuestionAnswer,
  properties: {
    [key: string]: JSONSchema7Definition
  },
  propertiesToExclude: string[],
  t: TFunction
) => FormValidation

const checkMutuallyExclusiveProperties: CheckMutuallyExclusiveProperties = (
  errors,
  formData,
  properties,
  propertiesToExclude,
  t
) => {
  if (propertiesToExclude.every((p) => p in properties)) {
    if (propertiesToExclude.every((p) => !formData[p])) {
      propertiesToExclude.forEach((p) =>
        errors[p].addError(t('anz.formErrorLabels.mutuallyExclusiveFields'))
      )
    }
  }

  return errors
}

type CheckValidString = (
  errors: FormValidation,
  formData: QuestionAnswer,
  properties: {
    [key: string]: JSONSchema7Definition
  },
  t: TFunction
) => FormValidation

export const checkValidString: CheckValidString = (
  errors,
  formData,
  properties,
  t
) => {
  Object.keys(properties).forEach((questionKey) => {
    const value = formData[questionKey]
    const toCheck: string =
      typeof value === 'string' ? value : JSON.stringify(value) ?? ''
    const errorMessage = getInvalidCharactesErrorMessage({ value: toCheck, t })
    if (errorMessage) errors[questionKey].addError(errorMessage)
  })

  return errors
}

export const getInvalidCharactesErrorMessage = ({
  value,
  t,
}: {
  value?: string
  t: TFunction
}) => {
  const invalidChars = value?.split('').filter((v) => !validateString(v))

  if (invalidChars?.length) {
    const errorKey =
      invalidChars.length === 1
        ? `anz.formErrorLabels.invalidString_one`
        : `anz.formErrorLabels.invalidString_other`

    return t(errorKey, {
      char: invalidChars.join(', '),
      count: invalidChars.length,
    })
  }
}

const clearFieldIfNotUsed = (
  currentSchema: Partial<jsonSchemaCustom>,
  formData: QuestionAnswer
): QuestionAnswer => {
  const dependencies = currentSchema.dependencies

  const localFormData = { ...formData }

  if (dependencies) {
    const dependenciesKeys = Object.keys(dependencies)

    // If there are conditional fields, I itereate them...
    dependenciesKeys.forEach((key) => {
      // ...and I read for the conditional properties.
      const dependentProperties = (
        (dependencies[key] as JSONSchema7).oneOf![0] as JSONSchema7
      ).properties

      // I check the value that trigger the condition.
      // EG: "Other" in some fields, trigger a text area where you specify other issues.
      const valueToCheck =
        ((dependentProperties![key] as JSONSchema7).contains as any)?.const ||
        (dependentProperties![key] as JSONSchema7).enum![0]

      // I check the fields that are activated when the condition is satisfied.
      const dependentKeys = Object.keys(dependentProperties!)
      const dependentKeyToNull = dependentKeys.filter(
        (v) => v !== key && (key === 'country' ? v !== 'state' : true)
      )

      // In the end, I iterate in the conditionally-activated fields and,
      // if the original field does NOT contains the trigger value,
      // I remove the field from the submit object.
      dependentKeyToNull.forEach((keyToNull) => {
        if (
          !!formData[key] &&
          !(formData[key] as string[]).includes(valueToCheck as string)
        ) {
          localFormData[keyToNull!] = isString(localFormData[keyToNull!])
            ? ''
            : undefined
        }
      })
    })
  }

  return localFormData
}

const parsedError = (
  name: string,
  defaultMessage: string,
  t: TFunction
): string => {
  const errorKey = `anz.formErrorLabels.${name}`
  const errorLabel = t(errorKey)

  return errorKey === errorLabel ? defaultMessage : errorLabel
}

const zipCodeValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
}: CustomValidateFunctionType) => {
  const zipCode = formData.zipCode?.toString()
  const currentFormCountry = formData.country?.toString()
  // if not present avoid validiting zip code
  if (!currentFormCountry) return

  if (
    zipCode &&
    (currentFormCountry === 'CAN'
      ? !customFormats.zipCodeCAN.test(zipCode)
      : !customFormats.zipCode.test(zipCode))
  ) {
    errors['zipCode'].addError(t('anz.formErrorLabels.format'))
  }
}

const phoneValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
}: CustomValidateFunctionType) => {
  const cellPhone = formData.cellPhone?.toString()

  if (cellPhone && !customFormats.cellPhone.test(cellPhone)) {
    errors['cellPhone'].addError(t('anz.formErrorLabels.format'))
  }
}

const homePhoneValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
}: CustomValidateFunctionType) => {
  const homePhone = formData.homePhone?.toString()

  if (homePhone && !customFormats.homePhone.test(homePhone)) {
    errors['homePhone'].addError(t('anz.formErrorLabels.format'))
  }
}

const medicareDvaNumberValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
}: CustomValidateFunctionType) => {
  const medicareDvaNumber = formData.medicareDvaNumber?.toString()

  if (
    medicareDvaNumber &&
    !customFormats.medicareDvaNumber.test(medicareDvaNumber)
  ) {
    errors['medicareDvaNumber'].addError(t('anz.formErrorLabels.format'))
  }
}

const medicareCardNumberValidationFn = ({
  formData,
  errors,
  t,
}: CustomValidateFunctionType) => {
  const setMedicareCardNumberError = () =>
    errors['medicareCardNumber'].addError(t('anz.formErrorLabels.format'))

  const medicareCardNumber = formData.medicareCardNumber
    ?.toString()
    .replace(/\s/g, '')
  if (!medicareCardNumber) {
    return
  }

  try {
    const isOnlyNumbers = new RegExp(/^\d+$/).test(medicareCardNumber)
    const firstDigit = parseInt(medicareCardNumber[0])

    if (
      medicareCardNumber.length !== 10 ||
      !isOnlyNumbers ||
      firstDigit < 2 ||
      firstDigit > 6
    ) {
      setMedicareCardNumberError()
      return
    }

    const dChkSum =
      parseInt(medicareCardNumber[0]) +
      parseInt(medicareCardNumber[1]) * 3 +
      parseInt(medicareCardNumber[2]) * 7 +
      parseInt(medicareCardNumber[3]) * 9 +
      parseInt(medicareCardNumber[4]) +
      parseInt(medicareCardNumber[5]) * 3 +
      parseInt(medicareCardNumber[6]) * 7 +
      parseInt(medicareCardNumber[7]) * 9

    if (isNaN(dChkSum) || dChkSum % 10 !== parseInt(medicareCardNumber[8])) {
      setMedicareCardNumberError()
    }
  } catch (error) {
    setMedicareCardNumberError()
  }
}

const checkInsuranceString = (insurance: unknown): insurance is string => {
  return Boolean(insurance) && typeof insurance === 'string'
}

const checkInsuranceDataValidity = (
  insurance: InsuranceFormData,
  insuranceType: InsuranceType
): boolean => {
  const {
    hasInsurancePlan,
    isPrimaryHolder,
    insuranceProvider,
    primaryMemberID,
    primaryMemberSSN,
    ...holderData
  } = insurance
  if (!hasInsurancePlan) return true
  const medicalInsuranceProviderData = { insuranceProvider, primaryMemberID }
  const primaryHolderData =
    insuranceType === 'medical'
      ? medicalInsuranceProviderData
      : { ...medicalInsuranceProviderData, primaryMemberSSN }
  const dataToCheck = isPrimaryHolder
    ? primaryHolderData
    : { ...primaryHolderData, ...holderData }

  return Object.values(dataToCheck)
    .flatMap((value) => (value === undefined ? [] : value))
    .every((value) => Boolean(value))
}

const INSURANCE_TYPES = Object.freeze(['vision', 'medical'] as const)

type InsuranceType = typeof INSURANCE_TYPES[number]

const INSURANCE_TYPE_FORM_DATA_KEY_MAP: Record<InsuranceType, string> =
  Object.freeze({
    vision: 'hasVisionInsurance',
    medical: 'hasMedicalInsurance',
  })

const BLANK_INSURANCE_HOLDER_DATA = {
  relationshipWithHolder: '',
  primaryHolderFirstName: '',
  primaryHolderLastName: '',
  primaryHolderGender: '',
  primaryHolderDOB: '',
  primaryHolderAddress: '',
  primaryHolderCity: '',
  primaryHolderCountry: '',
  primaryHolderZipCode: '',
  primaryHolderState: '',
}

const insuranceValidationFn = (
  insuranceType: InsuranceType,
  { formData, errors, config: { country } }: CustomValidateFunctionType
) => {
  const insuranceKey = INSURANCE_TYPE_FORM_DATA_KEY_MAP[insuranceType]
  const insurance = formData[insuranceKey]
  if (!checkInsuranceString(insurance)) {
    return
  }

  try {
    const { primaryMemberSSN, primaryHolderSSN, ...insuranceParsed } =
      JSON.parse(insurance || '{}')
    const additionalHolderData =
      country !== 'CAN' ? { primaryMemberSSN, primaryHolderSSN } : {}
    const fullInsuranceData = {
      ...BLANK_INSURANCE_HOLDER_DATA,
      ...additionalHolderData,
      ...insuranceParsed,
    }

    const isValid = checkInsuranceDataValidity(fullInsuranceData, insuranceType)

    !isValid &&
      errors[insuranceKey]?.addError(
        `Incomplete ${insuranceType} insurance data`
      )
  } catch (error) {
    console.error(`Error parsing ${insuranceType} Insurance :: ${error}`)
  }
}

const futureYearValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
  properties,
}: CustomValidateFunctionType) => {
  const yearToTest = formData.lastExam?.toString()
  const hasLastExam = properties && Object.keys(properties).includes('lastExam')

  if (!yearToTest || !hasLastExam) {
    return
  }

  const currentYear = new Date().getFullYear().toString()
  const pastYear = new Date().getFullYear() - 100

  // check if is year
  if (yearToTest && !customFormats.year.test(yearToTest)) {
    errors['lastExam'].addError(
      t('visionHistory.yearEyeglassPrescriptionRegexMessage')
    )
    return
  }

  // check is future year
  if (yearToTest && yearToTest > currentYear) {
    errors['lastExam'].addError(
      t('visionHistory.yearEyeglassPrescriptionYearInFuture')
    )
    return
  }

  // check is minus 100 years
  if (yearToTest && parseInt(yearToTest) - pastYear < 0) {
    errors['lastExam'].addError(
      t('visionHistory.yearEyeglassPrescriptionMoreThan100Years')
    )
    return
  }
}

const NON_EYEWEAR_OPTIONS = [
  'None',
  'Contact Lenses',
  'Aucun',
  'Lentilles De Contact',
  'Ninguno',
  'Lentes De Contacto',
]

export const isStringArray = (value: any): value is string[] => {
  return Array.isArray(value) && value.every((item) => typeof item === 'string')
}

export const isUsingEyewear = (selectedEyewear: string[]): boolean => {
  if (!selectedEyewear || !selectedEyewear?.length) return false

  return selectedEyewear.some((item) => !NON_EYEWEAR_OPTIONS.includes(item))
}

const futurePrescriptionYearValidationFn = ({
  formData,
  errors,
  t,
  customFormats,
  properties,
}: CustomValidateFunctionType) => {
  const yearToTest = formData.eyeglassPrescription?.toString()
  const eyeWearValue: string[] = (formData?.eyewear || []) as string[]
  const checkField = isUsingEyewear(eyeWearValue)
  const hasEyeglassPrescription =
    properties && Object.keys(properties).includes('eyeglassPrescription')

  if (!checkField || !hasEyeglassPrescription) {
    return
  }

  if (!yearToTest) {
    errors['eyeglassPrescription'].addError(t('anz.formErrorLabels.required'))
    return
  }

  const currentYear = new Date().getFullYear().toString()
  const pastYear = new Date().getFullYear() - 100

  // check if is year
  if (yearToTest && !customFormats.year.test(yearToTest)) {
    errors['eyeglassPrescription'].addError(
      t('visionHistory.yearEyeglassPrescriptionRegexMessage')
    )
    return
  }

  // check is future year
  if (yearToTest && yearToTest > currentYear) {
    errors['eyeglassPrescription'].addError(
      t('visionHistory.yearEyeglassPrescriptionYearInFuture')
    )
    return
  }

  // check is minus 100 years
  if (yearToTest && parseInt(yearToTest) - pastYear < 0) {
    errors['eyeglassPrescription'].addError(
      t('visionHistory.yearEyeglassPrescriptionMoreThan100Years')
    )
    return
  }
}

export {
  checkMutuallyExclusiveProperties,
  clearFieldIfNotUsed,
  futurePrescriptionYearValidationFn,
  futureYearValidationFn,
  homePhoneValidationFn,
  insuranceValidationFn,
  medicareCardNumberValidationFn,
  medicareDvaNumberValidationFn,
  parsedError,
  phoneValidationFn,
  zipCodeValidationFn,
}
