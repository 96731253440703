import { format, parse } from 'date-fns'
import { Field, Formik } from 'formik'
import React from 'react'
import { useTranslation } from 'react-i18next'
import styled from 'styled-components'
import Header from '../../components/Header'
import StepperNavigaton from '../../components/StepperNavigation'
import { selectStorePractice } from '../../features/practices/selectors'
import { useDateFormatter } from '../../hooks/useDateFormatter'
import { isEmeaRegion, isGeminiRegion } from '../../libs/region'
import { pxToRem } from '../../libs/style'
import { padTo2Digits } from '../../libs/time'
import { ProviderOption, TimeSlotFhir } from '../../model/appointment'
import { useTeloRouter } from '../../routing/teloRouter'
import {
	useGetAppointmentListQuery,
	useGetAppointmentProvidersListQuery,
} from '../../services/appointments'
import { useTeloSelector } from '../../store'
import {
	FieldsWrapperGrid,
	PageWrapperFlex,
	PrevButtonFooter,
	SmallerPageTitle,
	StyledForm,
	TitleWrapperWithBorder,
} from '../../styleguide/CommonPageComponents'
import FormattedDatePicker from '../../styleguide/FormattedDatePicker'
import Checkbox from '../../styleguide/forms/Checkbox'
import CheckboxGroup from '../../styleguide/forms/CheckboxGroup'
import Select from '../../styleguide/forms/Select'
import Grid from '../../styleguide/Grid'
import ChevronLeft from '../../styleguide/icons/ChevronLeft'
import CloseIcon from '../../styleguide/icons/CloseIcon'
import Stepper from '../../styleguide/Stepper'
import theme from '../../styleguide/theme'
import Subtitle from '../../styleguide/typography/Subtitle'
import Yup from '../../yup'
import AppointmentPatientForm from './AppointmentPatientFrom'

const BiggerSubtitle = styled(Subtitle)`
	font-size: 1.6rem;
	font-weight: normal;
	display: flex;
	justify-content: space-between;
	text-transform: capitalize;
	margin-bottom: ${theme.spacing(2)};
`

const Text = styled.div`
	color: ${theme.palette.grey[500]};
	margin-top: ${theme.spacing(2)};
	margin-bottom: ${theme.spacing(2)};
`
const TimeSlotsButtons = styled(CheckboxGroup)`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(7rem, 1fr));
	grid-column-gap: ${theme.spacing(3)};
	grid-row-gap: ${theme.spacing(2)};
	${theme.breakpoints.down('xl')} {
		grid-column-gap: ${theme.spacing(1.5)};
		grid-row-gap: ${theme.spacing(1)};
	}
	.MuiButtonBase-root {
		width: 100%;
	}

	/* give every label the same with no matter if the hour is single or douple digit - fix 554 */
	.button--label {
		min-width: ${pxToRem(70)}rem;
	}
`

const StyledFieldsWrapperGrid = styled(FieldsWrapperGrid)`
	width: 100%;
	margin: 0;
	padding: 0;
`
const StyledPageWrapper = styled(PageWrapperFlex)`
	overflow-x: hidden;
`

const step1ValidationSchema = Yup.object().shape({
	appType: Yup.string().required(),
	providerId: Yup.string().required(),
})

const STEP_REASONWHY = 0
const STEP_DATESTEP = 1
const STEP_FIRSTDATA = 2

const NewAppointmentPage = () => {
	const { t } = useTranslation()
	const { navigate, readRouteParams, readSearchParams, setSearchParams } =
		useTeloRouter()

	const { storeId } = readRouteParams({ name: 'storeId', required: true })

	const practice = useTeloSelector(selectStorePractice(storeId))
	const isTimeNotationFormat24 =
		practice[0]?.practiceConfig?.timeNotationFormat24
	const practiceId = practice?.[0]?._id

	const translateAppointmentTypes =
		isGeminiRegion || isEmeaRegion ? false : true

	React.useEffect(() => {
		if (practice.length > 0 && practice[0].directBooking === false) {
			navigate(`/store/${storeId}/worklist`)
		}
	}, [practice, storeId, navigate])

	const [isTelemed, setIsTelemed] = React.useState(false)
	const [selectedProvider, setSelectedProvider] = React.useState<
		ProviderOption | undefined
	>(undefined)

	const { data: appointmentTypes } = useGetAppointmentListQuery(
		practiceId || '',
		{
			skip: !practiceId,
		},
	)

	const appointmentTypeOptions =
		appointmentTypes?.map((type: string) => {
			return {
				value: type,
				label: translateAppointmentTypes ? t(`appointment.tab.${type}`) : type,
			}
		}) || []

	const { data: providers } = useGetAppointmentProvidersListQuery({
		locationId: storeId || '',
	})
	const providerOptions =
		providers?.map((provider: ProviderOption) => ({
			value: provider.id,
			label: provider.name + ' ' + provider.surname,
			isTelemed: provider.isTelemed,
		})) || []

	const setTelemedCheckbox = (providerId: string) => {
		const provider = providers?.find(provider => provider.id === providerId)

		setIsTelemed(provider?.isTelemed || false)
		setSelectedProvider(provider)
	}

	const { appType, step, slot, date } = readSearchParams(
		{
			name: 'appType',
			required: false,
		},
		{
			name: 'step',
			required: false,
		},
		{
			name: 'slot',
			required: false,
		},
		{
			name: 'date',
			required: false,
		},
	)

	const activeStep = step ? Number(step) : 0
	const setActiveStep = (step: number) => {
		setSearchParams({ step: step.toString() }, { replace: true })
	}

	const selectedSlot = slot
	const setSelectedSlot = (slotValue: string) => {
		setSearchParams({ slot: slotValue }, { replace: true })
	}

	const selectedDate = date ? date : format(new Date(), 'yyyy-MM-dd')

	const setSelectedDate = (selectedDate: string) => {
		setSearchParams({ date: selectedDate }, { replace: true })
	}

	const { formatDate } = useDateFormatter()

	const FirstStepBackBtn = (
		<PrevButtonFooter
			type="button"
			variant="text"
			onClick={() => navigate(`/store/${storeId}/worklist`)}
			startIcon={<CloseIcon />}
		>
			{t('app.close')}
		</PrevButtonFooter>
	)

	const availableSlots = new Array(24).fill(undefined).map((_, index) => {
		return {
			h: 9 + Math.floor(index / 2),
			m: index % 2 === 0 ? 0 : 30,
		}
	})

	const buildDate = (slot: TimeSlotFhir) => {
		if (!isTimeNotationFormat24) {
			if (slot.h === 12) {
				return `${slot.h}:${padTo2Digits(slot.m)} pm`
			}
			return `${padTo2Digits(slot.h % 12)}:${padTo2Digits(slot.m)} ${
				slot.h < 12 ? 'am' : 'pm'
			}`
		}
		return `${padTo2Digits(slot.h)}:${padTo2Digits(slot.m)}`
	}

	const slots = availableSlots.map(slot => ({
		value: `${slot.h}.${slot.m}`,
		label: buildDate(slot),
	}))

	return (
		<StyledPageWrapper>
			<Header />
			<TitleWrapperWithBorder>
				<SmallerPageTitle>
					{t('appointment.newAppointmentPageTitle')}
				</SmallerPageTitle>
			</TitleWrapperWithBorder>
			<Stepper
				activeStep={activeStep}
				steps={[
					t('app.reasonWhy'),
					t('app.dateStep'),
					t('appointment.demographicData'),
				]}
			/>

			{activeStep === STEP_REASONWHY && (
				<Formik
					initialValues={{
						appType: appType || '',
						providerId: selectedProvider || '',
					}}
					validateOnChange={false}
					validateOnBlur={true}
					onSubmit={() => {
						setActiveStep(activeStep + 1)
					}}
					validationSchema={step1ValidationSchema}
				>
					{() => (
						<StyledForm noValidate>
							<Grid flexGrow={1}>
								<Grid container flexDirection="column" margin={0}>
									<Grid container margin={0}>
										<Grid item xs={12} sm={6} md={4}>
											<BiggerSubtitle>{t('app.reasonWhy')}</BiggerSubtitle>
										</Grid>
									</Grid>
									<Grid container margin={0}>
										<Grid item xs={12} sm={6} md={4}>
											<Field
												name="appType"
												component={Select}
												fullWidth
												label={t('appointment.appointmentType')}
												options={appointmentTypeOptions}
												onChangeValue={(selectedValue: string) => {
													setSearchParams(
														{ appType: selectedValue },
														{ replace: true },
													)
												}}
											/>
										</Grid>
									</Grid>
									<Grid container alignItems="center" margin={0}>
										<Grid item xs={12} sm={6} md={4}>
											<Field
												name="providerId"
												component={Select}
												fullWidth
												label={t('appointment.provider')}
												options={providerOptions}
												onChangeValue={(e: any) => {
													setTelemedCheckbox(e)
												}}
												required
											/>
										</Grid>
										<Grid item xs={12} sm={6} md={4}>
											{selectedProvider !== undefined && (
												<Field
													name="teleHealth"
													component={Checkbox}
													label={t('appointment.teleHealth')}
													disabled={selectedProvider?.isTelemed || false}
													checked={isTelemed}
													onChange={(e: any) => {
														const { checked } = e.target
														setIsTelemed(checked)
													}}
												/>
											)}
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<StepperNavigaton
								activeStep={activeStep}
								numberOfSteps={3}
								disableNext={!appType}
								setActiveStep={setActiveStep}
								FirstStepBackBtn={FirstStepBackBtn}
							/>
						</StyledForm>
					)}
				</Formik>
			)}
			{activeStep === STEP_DATESTEP && (
				<Formik
					initialValues={{ timeSlot: [] }}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={() => {
						setActiveStep(activeStep + 1)
					}}
				>
					{({ values }) => (
						<StyledForm noValidate>
							<StyledFieldsWrapperGrid container>
								<Grid item md={12}>
									<BiggerSubtitle>
										{t('appointment.date')}: {formatDate(new Date())}
									</BiggerSubtitle>
									<FormattedDatePicker
										disablePast={true}
										initialDate={
											selectedDate
												? parse(selectedDate, 'yyyy-MM-dd', new Date())
												: new Date()
										}
										onChange={selectedDate =>
											setSelectedDate(
												selectedDate ? format(selectedDate, 'yyyy-MM-dd') : '',
											)
										}
									/>
									<Text>{t('appointment.slotsAvailable')}</Text>

									<TimeSlotsButtons
										name="timeSlot"
										multiSelectEntries={false}
										selectedEntries={values.timeSlot}
										totalEntries={slots}
										onSelectValue={e => {
											setSelectedSlot(e.value)
										}}
									/>
								</Grid>
							</StyledFieldsWrapperGrid>
							<StepperNavigaton
								activeStep={activeStep}
								numberOfSteps={3}
								disableNext={!selectedSlot || !selectedDate}
								setActiveStep={setActiveStep}
								StepBackInheritBtn={
									<PrevButtonFooter
										type="button"
										variant="text"
										onClick={() => {
											setActiveStep(activeStep - 1)
										}}
										startIcon={<ChevronLeft />}
									>
										{t('app.back')}
									</PrevButtonFooter>
								}
							/>
						</StyledForm>
					)}
				</Formik>
			)}
			{activeStep === STEP_FIRSTDATA && selectedProvider && appType && (
				<AppointmentPatientForm
					renderSubmit={props => (
						<StepperNavigaton
							activeStep={activeStep}
							numberOfSteps={3}
							setActiveStep={setActiveStep}
							nextLabel={t('appointment.confirmAppointment')}
							{...props}
						/>
					)}
					slot={slot}
					appType={appType}
					providerId={selectedProvider?.id}
					isTelemed={isTelemed}
					date={selectedDate}
				/>
			)}
		</StyledPageWrapper>
	)
}

export default NewAppointmentPage
